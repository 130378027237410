<script lang="ts">
import { TowerApi } from '@/api'
import { Tower } from '@/model'
import { useProjectStore } from '@/stores/project'
import { useTowerStore } from '@/stores/tower'
import { copy, unifyProjectionCode } from '@/util'
import { FeatureCollection, LineString } from 'geojson'
import { v4 } from 'uuid'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ImportStep',

  props: {
    formValues: {
      type: Object,
      default: () => ({ tower: undefined, span: undefined })
    },
    geojson: {
      type: Object as PropType<FeatureCollection>
    }
  },

  data: () => ({
    error: false as false | string,
    firstRun: true,
    importing: false,
    message: undefined as undefined | string,
    success: true
  }),

  computed: {
    projectId() {
      return this.$route.params.projectId as string
    },
    spanDefaults() {
      return this.formValues.span
    },
    towerDefaults() {
      return this.formValues.tower
    },
    towers() {
      if (!this.geojson) {
        return []
      }
      const towerCoordinates = (this.geojson.features[0].geometry as LineString).coordinates

      // break computed-reference to make values mutable
      const towerDefaults = copy(this.towerDefaults)
      if (!towerDefaults.in.earthwires) {
        towerDefaults.in.earthwires = []
      }
      if (towerDefaults.out && !towerDefaults.out.earthwires) {
        towerDefaults.out.earthwires = []
      }

      const towers: Tower[] = towerCoordinates.map((coord) => {
        const tower: Tower = {
          id: v4(),
          x: coord[0],
          y: coord[1],
          project: this.projectId,
          ...towerDefaults
        }

        return tower
      })
      return towers
    }
  },

  methods: {
    async runImport() {
      this.importing = true
      this.firstRun = false
      this.success = false

      try {
        this.error = false
        if (!this.geojson || !this.towers || !this.spanDefaults) {
          throw 'Ungültige Parameter aus den vorherigen Schritten übergeben.'
        }

        const payload = {
          project: this.projectId,
          list: this.towers,
          spanDefaults: this.spanDefaults
        }

        this.message = 'Masten importieren...'
        await TowerApi.saveList(payload)

        this.message = 'Referenzsystem setzen...'
        const projectStore = useProjectStore()
        const project = projectStore.findById(this.projectId)
        if (!project) {
          throw 'Projekt nicht gefunden. Konnte CRS nicht aktualisieren.'
        }
        project.crs = unifyProjectionCode((this.geojson as any).crs?.properties?.name)
        await projectStore.save(project)

        this.success = true
      } catch (e: any) {
        this.error = e.toString()
      } finally {
        this.importing = false
        const towerStore = useTowerStore()
        await towerStore.load(this.projectId)
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col min-h-[20rem] justify-center items-center max-w-[60ch] mx-auto">
    <transition mode="out-in" name="fade">
      <div v-if="firstRun || importing" class="flex flex-col space-y-4 items-center text-gray-500">
        <p class="text-center">
          Beim Import werden {{ towers.length }} Masten neu angelegt.
          <br />
          <b class="text-warning-500">Alle bestehenden Masten werden gelöscht.</b>
        </p>

        <p class="pb-4">Sind Sie sicher, dass Sie den Import durchführen wollen?</p>
        <p-btn :loading="importing" type="warning" @click="runImport">
          {{ message || 'Ja, Import starten' }}
        </p-btn>
      </div>
      <div v-else-if="error" class="bg-error-200 text-error-700 p-4 rounded w-full">
        <b>Fehler:</b>
        {{ error }}
      </div>
      <div v-else-if="success" class="flex flex-col items-center">
        <p-success-icon class="text-success-500 w-20 mb-4" />
        <p class="text-success-600 text-lg">Der Import war erfolgreich.</p>
      </div>
    </transition>
  </div>
</template>

<style lang="css" scoped></style>
